import * as React from 'react';

export default function HomePage(props) {
function myFunction() {
    const isBrowser = typeof window !== "undefined"
    if(isBrowser){
        window.location.replace("https://www.bhanzu.com/book-demo/?utm_source=offline-flyer&utm_medium=offline-flyer-distribution-india-url&utm_campaign=offline-flyer-distribution&utm_content=offline-flyer-distribution-india-url")
    }
}
myFunction()
  return ("redirecting...")
}
